<template>
  <div class="web-packages">
    <div class="packages-top">
      <div class="pt-left">
        <div class="imt-cats">
        <span class="imt-title" v-if="categoryList.length>0" v-for="(item,index) in categoryList"
              :class="{activeCat:catIndex==index}" @click="choiceCat(index,item.id)">{{item.catName}}</span>
          <span class="imt-title1" v-if="!categoryList.length>0">暂无分类</span>
        </div>
        <div class="gd">
          <div class="packages-items" v-if="composes.length>0">
            <div class="packages-item" v-for="item in composes" @click="addCompose(item)">
              <img src="../../assets/images/tc.png" class="packages-img">
              <span class="cpi-name">{{item.composeName}}</span>
              <span class="cpi-price">¥ {{item.tcPrice}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-right">
        <div class="tc-cjmb">
          <el-button size="small" style="height: 32px;" type="primary" @click="toMembers">查询客户</el-button>
          <el-button size="small" type="primary" @click="toAddMember">创建客户</el-button>
          <div v-if="member.memberName">
            <span style="margin-left: 28px">顾客: {{member.memberName}}</span>
            <span style="margin-left: 6px;color: #ff4d51;cursor: pointer" @click="toMemberCards"> {{memberCard.cardName}}</span>
          </div>
        </div>
        <div class="tc-hd">
          <span style="width: 40%">名称</span>
          <span style="width: 30%">价格</span>
          <span style="width: 30%">销售</span>
        </div>
        <div class="buy-packages" style="overflow-y:scroll;">
          <div style="height: 100%;">
            <div v-if="datas" v-for="(item,index) in datas">
              <div class="tc-hd1" :class="{'active':index==tabIndex}" @click="setRow(item)">
                <div style="width: 40%;text-align: left;font-size: 14px;">
                  <i class="el-icon-delete-solid"
                     style="margin: 0px 20px;font-size: 16px;line-height: 30px;cursor: pointer"
                     @click.stop="delCompose(index)"></i>
                  {{item.commodityName}}
                </div>
                <div style="width: 30%;font-size: 14px;">
                  <span style="display: block">{{item.price}}</span>
                </div>
                <div style="width: 30%;font-size: 14px;">
                  <div class="buy-xiaoshou" v-if="item.emps">
                    <span style="line-height: 28px;font-size: 13px;display: block" v-for="it in item.emps">{{it.empName}}</span>
                  </div>
                </div>
              </div>
              <div class="tc-item" v-for=" ci in item.composeItems">
                <span style="width: 40%;text-align: left;"><i style="margin: 0px 29px;"></i>{{ci.itemName}}</span>
                <span style="width: 30%;">x</span>
                <span style="width: 30%;">{{ci.quantity}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="packages-emp" v-if="exist">
          <div class="packages-gw" v-if="fEmps.length>0">
            <span class="gw-title">{{fName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in fEmps" @click="setEmp(item,1)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
          <div class="packages-gw" v-if="sEmps.length>0">
            <span class="gw-title">{{sName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in sEmps" @click="setEmp(item,2)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
          <div class="packages-gw" v-if="tEmps.length>0">
            <span class="gw-title">{{tName}}</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in tEmps" @click="setEmp(item,3)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="packages-bottom">
      <div class="cjs-btn">
        <span>总计：</span>
        <span class="total">￥{{ total }}</span>
        <button class="payment" @click="saveComposeOrder">结 算</button>
      </div>
    </div>

    <!--表单组件-->
    <el-dialog append-to-body :close-on-click-modal="false" :before-close="closeEvent" :visible.sync="visible"
               :title="title" width="300px">
      <div>
        <div style="margin-bottom: 12px">
          <el-radio-group v-model="miType" @change="setDays(miType)">
            <el-radio :label="3">日常套餐</el-radio>
            <el-radio :label="4">月卡</el-radio>
            <el-radio :label="5">季卡</el-radio>
            <el-radio :label="6">年卡</el-radio>
          </el-radio-group>
        </div>
        <div v-if="miType==3">
          <el-form ref="form" size="small" label-width="110px">
            <el-form-item label="购买次数">
              <el-input-number v-model="gmcs" style="width: 250px;" :min="0" @input="setZj"/>
            </el-form-item>
            <el-form-item label="赠送次数">
              <el-input-number v-model="zscs" style="width: 250px;" :min="0" @input="setZj"/>
            </el-form-item>
            <el-form-item label="总价">
              <el-input v-model="zj" style="width: 250px;" disabled/>
            </el-form-item>
            <el-form-item label="套餐价">
              <el-input v-model="tcj" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="有效期">
              <el-input v-model="days" style="width: 250px;"/>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="miType==4||miType==5||miType==6">
          <el-form ref="form" size="small" label-width="110px">
            <el-form-item label="购买次数">
              <el-radio-group v-model="buyRadio" style="margin-top: 10px">
                <el-radio :label="1">不限次数</el-radio>
                <p></p>
                <el-radio :label="2">次数</el-radio>
                <el-input-number v-model="gmcs" style="width: 168px;margin-top: 5px" :min="0"/>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="手工费">
              <el-input v-model="price" style="width: 250px;"/>
              <p style="font-size: 12px">每次使用套餐手工费,输入"0"代表不需要另外支付费用</p>
            </el-form-item>
            <el-form-item label="套餐价">
              <el-input v-model="tcj" style="width: 250px;"/>
            </el-form-item>
            <el-form-item label="有效期">
              <el-input v-model="days" style="width: 250px;" disabled/>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEvent">取消</el-button>
        <el-button size="small" type="primary" @click="addDxtc(miType)">确认</el-button>
      </div>
    </el-dialog>

    <TabMemberList
      v-if="mbVisible"
      :mbVisible="mbVisible"
      @setMember="setMember"
      @setMemberCard="setMemberCard"
      @closeML="closeML"
      @toAddMember="toAddMember"
    />
    <TabSaveMember
      v-if="smVisible"
      :smVisible="smVisible"
      @setMember="setMember"
      @closeSM="closeSM"
    />
    <TabPayment
      v-if="payVisible"
      :payVisible="payVisible"
      :total="total"
      :data="data"
      :memberCard="memberCard"
      @closePay="closePay"
      @fanhui="fanhui"
    />
    <TabMemberCards
      v-if="mcVisible"
      :mcVisible="mcVisible"
      :memberId="memberId"
      :memberCardId="mcId"
      @setMc="setMc"
      @closeMC="closeMC"
    />
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";
  import TabMemberList from "../packages/member/memberList";
  import TabSaveMember from "../common/member/saveMember";
  import TabMemberCards from "../common/member/memberCards";
  import TabPayment from "../packages/payment/payment";

  export default {
    name: "packages",
    components: {
      TabMemberList,
      TabSaveMember,
      TabMemberCards,
      TabPayment,
    },
    data() {
      return {
        title: '',
        orderId: '',
        composes: [],
        datas: [],
        tabIndex: -1,

        mbType: 'tc',

        catId: '',
        catIndex: 0,
        categoryList: [],

        exist: false,
        fEmps: [],
        sEmps: [],
        tEmps: [],

        member: {},
        memberId: '',
        orderSex: '',
        members: [],
        memberCard: {},
        mcId: '',

        total: 0,
        data: {},

        saveVisible: false,
        mbVisible: false,
        smVisible: false,
        mcVisible: false,
        payVisible: false,
        loading: false,

        miType: 3,
        gmcs: 0,
        zscs: 0,
        zj: 0,
        price: 0,
        tcj: 0,
        days: 0,
        tcItem: {},
        visible: false,
        buyRadio: 1,
      };
    },

    async created() {
      await this.getCategoryList()
      await this.getEmpList();
    },

    methods: {
      //获取项目分类列表
      async getCategoryList() {
        let res = await this.$get("/sw/getSwCategoryList", {token: getToken(), catType: 2});
        this.categoryList = res.categoryList;
        if (res.categoryList.length > 0) {
          this.catId = res.categoryList[0].id
          if (this.catId == 'DXTC') {
            this.getDxtcList()
          } else {
            this.getComposes()
          }
        }
      },

      choiceCat(index, catId) {
        this.catIndex = index
        this.catId = catId
        if (catId == 'DXTC') {
          this.getDxtcList()
        } else {
          this.getComposes()
        }
      },

      //获取套餐列表
      async getComposes() {
        if (!this.catId) {
          return;
        }
        let res = await this.$get("/sw/getSwComposeList", {catId: this.catId, token: getToken()});
        this.composes = res.composes;
      },

      //获取套餐列表
      async getDxtcList() {
        let res = await this.$get("/sw/getSwComposeSingleList", {token: getToken()});
        this.composes = res.composes;
      },

      //添加套餐
      addCompose(item) {
        if (item.tcType == 1) {
          this.miType = 3
          this.tcItem = item
          this.title = item.composeName
          this.gmcs = 0
          this.zscs = 0
          this.zj = 0
          this.tcj = 0
          this.days = 0
          this.visible = true
        }
        if (item.tcType == 0) {
          this.datas.push({
            commodityId: item.id,
            categoryId: item.categoryId,
            commodityName: item.composeName,
            price: item.tcPrice,
            oglPrice: item.tcPrice,
            cost: item.cost,
            cmnVal: item.cmnVal,
            composeItems: item.composeItems,
            tcType: item.tcType,
            miType: 0,
            quantity: 0,
            emps: [],
          })
          this.tabIndex = this.datas.length - 1
          var to = parseFloat(this.total) + parseFloat(item.tcPrice)
          this.total = to.toFixed(1)
        }
      },
      setDays(miType) {
        if (miType == 3) {
          this.days = 0
        }
        if (miType == 4) {
          this.days = 30
        }
        if (miType == 5) {
          this.days = 90
        }
        if (miType == 6) {
          this.days = 360
        }
      },
      addDxtc(miType) {
        let quantity = 0
        if (miType == 3) {
           quantity = parseInt(this.gmcs) + parseInt(this.zscs)
          if (quantity <= 0) {
            this.$message.error("请输入正确的套餐数量!");
            return;
          }
        }else {
          if (this.buyRadio == 1) {
            quantity = -1
          }
          if (this.buyRadio == 2) {
            quantity = this.gmcs
          }
        }
        let composeItems = [
          {
            itemName: this.tcItem.composeName,
            quantity: quantity
          }
        ]
        this.datas.push({
          commodityId: this.tcItem.id,
          categoryId: '',
          commodityName: this.tcItem.composeName,
          price: this.tcj,
          oglPrice: this.tcj,
          sgPrice: this.price,
          cost: this.tcItem.cost,
          cmnVal: this.tcItem.cmnVal,
          composeItems: composeItems,
          tcType: this.tcItem.tcType,
          quantity: quantity,
          days: this.days,
          miType: miType,
          emps: [],
        })
        this.tabIndex = this.datas.length - 1
        var to = parseFloat(this.total) + parseFloat(this.tcj)
        this.total = to.toFixed(1)
        this.visible = false
      },

      // 计算总价
      setZj() {
        let gmcs = 0
        if (this.gmcs > 0) {
          gmcs = parseFloat(this.gmcs) + parseFloat(gmcs)
        }
        if (this.zscs > 0) {
          gmcs = parseFloat(this.zscs) + parseFloat(gmcs)
        }
        if (gmcs > 0) {
          let tzj = parseFloat(this.tcItem.tcPrice) * parseFloat(gmcs)
          this.zj = tzj.toFixed(2)
        } else {
          this.zj = 0
        }
      },

      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
        this.fName = res.fName;
        this.sName = res.sName;
        this.tName = res.tName;
        this.exist = res.exist;
      },

      //移除已选择的会员卡
      delCompose(index) {
        let item = this.datas[index]
        this.datas.splice(index, 1)
        var to = parseFloat(this.total) - parseFloat(item.price)
        this.total = to.toFixed(1)
        this.getEmpList()
        this.tabIndex = index - 1
      },

      //选择会员信息
      setMember(mb) {
        this.smVisible = false
        if (mb.id) {
          this.member = mb;
          this.memberId = mb.id
          this.memberCard = {}
          this.mcId = ''
        }
        this.closeML();
      },

      setMemberCard(mb, mc) {
        this.smVisible = false
        if (mb.id) {
          this.member = mb;
          this.memberId = mb.id
        }
        if (mc.id) {
          this.memberCard = mc
          this.mcId = mc.id
        }
        this.closeML();
      },


      //编辑会员姓名
      toUpdateName() {

      },
      closeEvent() {
        this.visible = false
      },
      toMemberCards() {
        this.mcVisible = true
      },

      //计算总金额
      calculation(total) {
        this.total = total
      },

      setRow(item) {
        this.tabIndex = this.datas.indexOf(item);
        this.setEmpStatus(item.emps)
      },

      //设置员工
      async setEmp(item, type) {
        if (this.tabIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          var is = item.check;
          if (!is) {
            this.datas[this.tabIndex].emps.push({
              empId: item.id,
              empName: item.nickName,
              station: type,
            })
          } else {
            this.datas[this.tabIndex].emps.splice(item, 1)
          }
          item.check = !is
        }
      },

      async saveComposeOrder() {
        if (!this.memberId) {
          this.$message.error("请选择会员")
          return;
        }
        if (this.datas.length == 0) {
          this.$message.error("请选择套餐")
          return;
        }
        var mcId = ''
        if (this.memberCard && this.memberCard.id) {
          mcId = this.memberCard.id
        }
        var data = {
          composeStr: JSON.stringify(this.datas),
          total: this.total,
          memberId: this.memberId,
          mcId: mcId,
          orderSex: this.orderSex,
          token: getToken(),
          payType: '',
        }
        this.data = data
        this.payVisible = true;
      },

      closePay() {
        this.member = {}
        this.memberCard = {}
        this.datas = []
        this.total = 0
        this.getEmpList()
        this.payVisible = false;
        this.$message.success("结算成功")
      },

      fanhui() {
        this.payVisible = false;
      },

      toAddMember() {
        this.smVisible = true;
      },

      closeSM() {
        this.smVisible = false;
      },

      setMc(item) {
        this.memberCard = item
        this.mcId = item.id
        this.mcVisible = false;
      },

      closeMC() {
        this.mcVisible = false;
      },

      toMembers() {
        this.mbVisible = true;
      },

      closeML() {
        this.mbVisible = false;
      },

      // 将选中的人状态更改为没选中
      setUnchecked() {
        this.changeEmpFalse(this.fEmps);
        this.changeEmpFalse(this.sEmps);
        this.changeEmpFalse(this.tEmps)
      },

      // 切换套餐更改员工选中状态
      setEmpStatus(emps) {
        this.changeEmpFalse(this.fEmps);
        this.changeEmpFalse(this.sEmps);
        this.changeEmpFalse(this.tEmps);
        for (var i = 0; i < emps.length; i++) {
          let emp = emps[i];
          if (emp.station == 1) {
            this.changeEmpTrue(this.fEmps, emp, true)
          }
          if (emp.station == 2) {
            this.changeEmpTrue(this.sEmps, emp, true)
          }
          if (emp.station == 3) {
            this.changeEmpTrue(this.tEmps, emp, true)
          }
        }
      },
      //更改状态
      changeEmpTrue(emps, emp, check) {
        emps.forEach(item => {
          if (emp.empId == item.id) {
            item.check = check
          }
        })
      },

      //更改状态
      changeEmpFalse(emps) {
        emps.forEach(item => {
          item.check = false
        })
      },
    },

  };
</script>


<style lang="scss" scoped>
  .web-packages {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    margin-right: 5px;
  }

  .packages-top {
    width: 100%;
    height: 93%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
  }

  .pt-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
  }

  .pt-title {
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: #E6E6E6 solid 1px;
    text-align: center;
    font-weight: bold;
  }

  .gd {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
  }

  .packages-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .packages-item {
    /*flex: 1;*/
    box-sizing: border-box;
    width: 178px;
    height: 138px;
    padding: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    /*width: calc((100% - 60px) / 4); // 我这里一行显示4个 所以是/4  一行显示几个就除以几*/
    /*min-width: calc((100% - 60px) / 4);*/
    /*max-width: calc((100% - 60px) / 4);*/

    /*&:nth-child(4n + 4) {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  .packages-item:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }

  .packages-img {
    width: 100%;
    height: 98px;
    border-radius: 3px;
  }

  .cpi-name {
    margin-top: 3px;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
  }

  .cpi-price {
    line-height: 20px;
    margin-left: 5px;
    color: red;
    font-size: 15px;
  }

  .pt-right {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .tc-cjmb {
    display: flex;
    height: 50px;
    line-height: 50px;
    border-bottom: #E6E6E6 solid 1px;
    flex-direction: row;
    padding-left: 26px;
    border-right: #F2F2F2 solid 5px;
    justify-items: center;
    align-items: center;
  }

  .tc-hd {
    display: flex;
    flex-direction: row;
    border-bottom: #E6E6E6 solid 1px;
    align-items: center;
  }

  .tc-hd span {
    text-align: center;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
  }

  .tc-hd1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
    border-bottom: #E6E6E6 solid 1px;
  }

  .tc-hd1 span {
    text-align: center;
    height: 26px;
    line-height: 26px;
  }

  .tc-item {
    display: flex;
    flex-direction: row;
    border-bottom: #E6E6E6 solid 1px;
    align-items: center;
  }

  .tc-item span {
    text-align: center;
    height: 26px;
    line-height: 26px;
    font-size: 11px;
    color: #ACABAB;
  }

  .buy-packages {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }

  .buy-xiaoshou {
    display: flex;
    flex-direction: column;
  }

  .packages-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .packages-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
  }

  .gw-emp .hui {
    width: 90%;
    margin-left: 5px;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .packages-bottom {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 6%;
    background-color: #FFFFFF;
  }

  .cjs-btn {
    width: 328px;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
  }

  .cjs-btn span {
    line-height: 51px;
    width: 50px;
  }

  .cjs-btn .total {
    width: 50%;
    color: red;
  }

  .payment {
    float: right;
    width: 150px;
    height: 51px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
  }

  .bp-nr {
    display: flex;
    flex-direction: column;
  }

  .bp-sp {
    width: 100%;
    border: #C4C4C4 solid 1px;
    margin: 1px 0px;
    border-radius: 3px;
  }

  ::v-deep .el-table__body tr.current-row > td {
    color: #000000;
    background: #EEE9E9 !important;
  }


  .imt-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
    overflow: hidden;
  }

  .imt-cats {
    width: 100%;
    border-bottom: #E6E6E6 solid 1px;
    height: 48px;
    line-height: 48px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .imt-title {
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .imt-title1 {
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
  }

  .activeCat {
    color: red;
    border-bottom: red solid 2px;
  }

  .active {
    background-color: #EEE9E9;
  }


</style>

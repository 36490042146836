<template>
  <el-dialog :close-on-click-modal="false" title="会员列表" :visible.sync="mbVisible" width="800px"
             :before-close="closeEvent">
    <div class="mbs">
      <div class="mbs-items">
        <div class="mbs-mb" v-if="mbs.length>0" v-for="item in mbs">
          <div>
            <img v-if="!item.headPortrait&&item.sex == 0" src="../../../assets/images/nan.png" class="mb-img">
            <img v-if="!item.headPortrait&&item.sex == 1" src="../../../assets/images/nan.png" class="mb-img">
            <img v-if="!item.headPortrait&&item.sex == 2" src="../../../assets/images/nv.png" class="mb-img">
            <img v-if="item.headPortrait" :src="item.headPortrait" class="mb-img">
          </div>
          <div style="display:block;margin-left: 8px;width: 100%;cursor: pointer" @click="xunMember(item)">
            <div class="mbs-name">
              <span>{{item.memberName}}</span>
              <span style="margin-left: 10px">{{item.phone}}</span>
            </div>
            <div class="mbs-item" v-if="item.memberCards.length>0">
              <span>会员卡: {{item.memberCards[0].cardName}}</span>
              <span>余额: {{item.memberCards[0].balance}}<span style="margin-left: 18px">赠送金: {{item.memberCards[0].giveBalance}}</span></span>
            </div>
            <div class="mbs-item" v-if="item.memberCards.length<=0">
              <span>会员卡: 无</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mbs-box">
        <span class="v-form-label">手机号后四位 / 姓名:</span>
        <el-input placeholder="请输入手机号后四位 / 姓名" size="mini" style="width: 265px; display: inline-block"
                  v-model="money"
                  clearable
                  class="handle-input mr10"
                  @keyup.enter.native="searchEvent"
        ></el-input>
        <el-row type="flex" ustify="end" style="margin-left: 30px; display: inline-block">
          <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询</el-button>
        </el-row>
      </div>
    </div>
    <div style="margin-bottom: 38px;height: 400px">
      <div class="information1" v-if="showList">
        <span v-if="members.length <= 0">暂无数据</span>
        <div style="overflow: auto;;height: 360px;">
          <table
                  cellpadding="0"
                  cellspacing="0"
                  border="0"
                  width="100%"
                  style="text-align: center"
          >
            <tbody>
            <tr v-for="(row, index) in members" :key="row.id">
              <td class="td-l" style="width: 60%;border-top: 2px rgba(242, 242, 242, 1) solid;">
                <div class="mbes">
                  <div>
                    <div>
                      <img v-if="!row.headPortrait&&row.sex == 0" src="../../../assets/images/nan.png" class="mb-img">
                      <img v-if="!row.headPortrait&&row.sex == 1" src="../../../assets/images/nan.png" class="mb-img">
                      <img v-if="!row.headPortrait&&row.sex == 2" src="../../../assets/images/nv.png" class="mb-img">
                      <img v-if="row.headPortrait" :src="row.headPortrait" class="mb-img">
                    </div>
                  </div>
                  <span class="fudong">
                      <span>{{ row.memberName }}</span>
                    </span>
                  <div class="fudong">
                    <span v-if="row.sex == 0">
                      未知
                    </span>
                    <span v-if="row.sex == 1">
                     男
                    </span>
                    <span v-if="row.sex == 2">
                     女
                    </span>
                  </div>
                  <span class="fudong">
                   {{ row.phone }}
                    </span>
                  <span class="fudong">
                   {{ row.deptName }}
                    </span>
                  <span class="fudong" style="width: 80px;text-align: right;padding-right: 10px">
                    <el-button type="warning" size="mini" plain @click="setMember(row)">选择</el-button>
                    </span>
                </div>
                <div
                        class="mcs"
                        v-if="row.memberCards"
                        v-for="item in row.memberCards"
                >
                  <div class="mcitem" style="display: flex;flex-direction: row;align-items: center">
                    <img :src="item.imgUrl" class="cardimg"/>
                    <div style="display: flex;flex-direction: column;margin-left: 10px;text-align: left">
                      <span style="height: 20px;line-height: 20px">{{item.cardName}} ( {{item.itemDiscount}}折 )</span>
                      <span style="height: 20px;line-height: 20px;color: #A4A2A3">卡号: {{item.mcNo}}</span>
                    </div>
                  </div>
                  <div class="mcitem">余额 : ¥{{item.balance}}</div>
                  <div class="mcitem">赠送金 : ¥{{item.giveBalance}}</div>
                  <div class="mcitem" style="width: 80px;text-align: right;padding-right: 10px">
                    <el-button type="success" icon="el-icon-check" size="mini" circle
                               @click="setMemberCard(row,item)"></el-button>
                    <!--                    <span style="cursor: pointer;color: #2b94ff;">选择</span>-->
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :pager-count="7"
                  layout="total, prev, pager, next,jumper"
                  :total="pagination.count"
                  :page-count="pagination.pages"
                  :current-page="pagination.pageNumber"
          ></el-pagination>
        </div>
      </div>
      <div class="mb-container" v-if="!showList">
        <div class="keyboard" @click.stop="_handleKeyPress">
          <div class="key-row">
            <div class="key-cell" data-num="1">1</div>
            <div class="key-cell" data-num="2">2</div>
            <div class="key-cell" data-num="3">3</div>
            <div class="key-cell el-icon-back" data-num="D"></div>
          </div>
          <div class="key-row">
            <div class="key-cell" data-num="4">4</div>
            <div class="key-cell" data-num="5">5</div>
            <div class="key-cell" data-num="6">6</div>
            <div class="key-cell el-icon-close" data-num="C"></div>
          </div>
          <div class="key-row">
            <div class="key-cell" data-num="7">7</div>
            <div class="key-cell" data-num="8">8</div>
            <div class="key-cell" data-num="9">9</div>
            <div class="key-cell" data-num="0">0</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import {getToken} from "../../../utils/auth";

  export default {
    data() {
      return {
        money: "",

        token: '',
        userId: '',
        members: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1,
        },
        showList: false,
        mbs: [],
      };
    },
    props: {
      mbVisible: Boolean,
      mbType: String
    },
    watch: {
      money(val) {
        if (!val) {
          this.showList = false;
        }
      },
    },
    created() {
      this.token = getToken()
      this.getRecentlyMembers()
    },
    methods: {
      async getRecentlyMembers() {
        let data = {
          token: this.token,
        };
        let res = await this.$get("/sw/getRecentlyMembers", data);
        this.mbs = res.list
      },
      _handleKeyPress(e) {
        let num = e.target.dataset.num;
        if (
                e.target.className == "key-cell" ||
                e.target.className == "key-cell el-icon-back" ||
                e.target.className == "key-cell el-icon-close" ||
                e.target.className == "key-cell el-icon-check"
        ) {
          //不同按键处理逻辑
          // -1 代表无效按键，直接返回
          if (num == -1) return false;
          switch (String(num)) {
                  //删除键
            case "D":
              this._handleDeleteKey();
              break;
                  //清空键
            case "C":
              this._handleClearKey();
              break;
            default:
              this._handleNumberKey(num);
              break;
          }
        }
      },
      //处理删除键
      _handleDeleteKey() {
        let S = this.money;
        //如果没有输入，直接返回
        if (!S.length) return false;
        //否则删除最后一个
        this.money = S.substring(0, S.length - 1);
      },
      //处理清空键
      _handleClearKey() {
        this.money = "";
      },

      //处理数字
      _handleNumberKey(num) {
        let S = this.money;
        this.money = S + num;
      },
      sanke() {
        this.$emit("sanke");
      },

      closeEvent() {
        this.$emit("closeML");
      },

      setMember(mb) {
        this.$emit("setMember", mb);
      },

      setMemberCard(mb, mc) {
        this.$emit("setMemberCard", mb, mc);
      },

      xunMember(mb) {
        if (mb.memberCards.length > 0) {
          let mc = mb.memberCards[0]
          this.$emit("setMemberCard", mb, mc);
        } else {
          this.$emit("setMember", mb);
        }
      },

      searchEvent() {
        this.getMembers();
      },

      handleCurrentChange(page) {
        this.getMembers(page);
      },

      async getMembers(page) {
        if (!this.money) {
          this.$message.error("请输入要搜索的手机号 / 姓名!");
          return;
        }
        let currentPage = page || 1;
        let data = {
          token: this.token,
          name: this.money,
          isDel: 0,
          size: 10,
          currentPage,
        };
        let res = await this.$get("/sw/getSwMemberForPage", data);
        if (res.code == 200) {
          this.showList = true;
          this.members = res.dataSet.list;
          this.pagination = {
            count: res.dataSet.count,
            pageNumber: res.dataSet.pageNumber,
            pageSize: res.dataSet.pageSize,
            pages: res.dataSet.pages,
          };
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .mbs {
    text-align: center;
    margin-top: -10px;
  }

  .mbs-items {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 30px;
  }

  .mbs-mb {
    width: 33%;
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 10px;
    margin: 0px 10px
  }

  .mb-img {
    width: 45px;
    height: 45px;
    float: left;
    border-radius: 30px;
    border: #E6E6E6 solid 1px
  }
  .mbs-name {
    width: 100%;
    height: 20px;
    line-height: 20px;
  }
  .mbs-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 20px;
    line-height: 20px;
  }

  .tab-img {
    width: 30px;
    height: 30px;
    display: block;
    align-items: center;
    margin: auto;
  }

  ::v-deep.el-table th, .el-table td {
    padding: 5px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }

  ::v-deep .el-table__header tr,
  .el-table__header th {
    padding: 0;
    height: 32px;
    font-size: 13px;
    text-align: center;
  }

  //调整表格高度
  ::v-deep .el-table__body tr,
  .el-table__body td {
    padding: 0;
    height: 32px;
    font-size: 13px;
  }

  ::v-deep .pagination {
    margin: 5px 0;
    text-align: right;
    position: relative;
    bottom: 0px;
  }

  ::v-deep .el-table td {
    padding: 5px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
    font-size: 13px;
  }

  .mb-container {
    text-align: center;
    height: 328px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .key-cell {
    display: inline-block;
    width: 68px;
    height: 68px;
    line-height: 68px;
    border-radius: 50%;
    border: #222222 solid 1px;
    margin-right: 10px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 22px;
  }

  .td-l {
    padding: 18px 10px 18px 10px;
  }

  .key-container {
    text-align: center;
  }


  .information1 {
    width: 100%;
    height: 400px;
    margin-top: 16px;
    text-align: center;
    overflow-y: scroll;
  }


  /*会员套餐详情*/
  .mbes {
    text-align: left;
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .mbes .fudong {
    width: 24%;
    height: 45px;
    text-align: center;
    line-height: 45px;
  }

  .mcs {
    width: 100%;
    margin-top: 10px;
    height: 45px;
    line-height: 45px;
    display: flex;
    flex-direction: row;
    background-color: #F2F2F2;
    font-size: 11px;
  }

  .mcitem {
    width: 33%;
  }

  .cardimg {
    width: 40px;
    height: 23px;
    margin-left: 8px;
  }

</style>
